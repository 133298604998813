
import {defineComponent, onMounted, reactive, toRefs} from "vue";
import ApiLogistics from "@/request/apis/api_logistics";
import {IComplaintItem} from "@/models/logistics_model";
import {useRoute} from "vue-router";
import {dateFormat} from "@/utils/common";
import {message} from "ant-design-vue/es";
import {
  getOrderMode,
  getPayMethod,
  getPayStatus,
  getShipMethod,
    getLogisticsOrderStatus,
} from "@/utils/status";

interface IState {
  detail?: IComplaintItem
  visible: boolean
  reason: string
  loading: boolean,
  pass: boolean
  data: {
    malicious: '1' | '0',// 是否恶意投诉或低质量服务 1是,0否
    responsible_party: '0' | '1' | '2' | '3',// 0双方无责 1司机责任 2用户责任 3双方都有责任
    remark: string, //备注
    compensation: string,// 实际赔偿金额
  }
}

export default defineComponent({
  name: "ComplaintDetail",
  setup() {
    let editId: any;
    const route = useRoute()

    const state: IState = reactive({
      detail: undefined,
      visible: false,
      reason: '',
      loading: false,
      pass: false,
      data: {
        malicious: '0',// 是否恶意投诉或低质量服务 1是,0否
        responsible_party: '0',// 0双方无责 1司机责任 2用户责任 3双方都有责任
        remark: '', //备注
        compensation: '',// 实际赔偿金额
      }
    })

    // 获取文章详情
    const getDetail = async () => {
      const {id} = route.query
      if (id) {
        editId = id
        const res = await ApiLogistics.getComplaintDetail(id.toString())
        if (res && res.error_code === 0 && res.data) {
          const data = res.data
          state.detail = data
          state.data = {
            malicious: '0',// 是否恶意投诉或低质量服务 1是,0否
            responsible_party: '0',// 0双方无责 1司机责任 2用户责任 3双方都有责任
            remark: '', //备注
            compensation: res.data.complaints[0].compensation || '',// 实际赔偿金额
          }
        }
      }
    }

    // 仲裁
    const onArbitration = async () => {
      const data = state.data
      state.loading = true
      const res = await ApiLogistics.onArbitration({
        consignment_complaint_id: state.detail?.complaints[0]?.id,
        malicious: Number(data.malicious),// 是否恶意投诉或低质量服务 1是,0否
        responsible_party: Number(data.responsible_party),// 0双方无责 1司机责任 2用户责任 3双方都有责任
        remark: data.remark || '无', //备注
        compensation: Number(data.compensation),// 实际赔偿金额
      })
      state.loading = false
      if (res && res.error_code === 0) {
        message.success('操作成功')
        await getDetail()
      } else {
        message.error(res?.msg || '审核失败');
      }
    }

    // 设置modal显示隐藏
    const setVisible = (visible: boolean, pass: boolean) => {
      state.visible = visible
      state.pass = pass
      state.reason = ''
    }

    onMounted(() => {
      getDetail()
    })

    return {
      ...toRefs(state),
      dateFormat,
      setVisible,
      onArbitration,
      getLogisticsOrderStatus,
      getPayStatus,
      getPayMethod,
      getShipMethod,
      getOrderMode,
    }
  }
})
